<template>
  <el-drawer
    class="elDrawerDetail"
    :title="comName"
    v-model="dialogTableVisible"
    :direction="direction"
    destroy-on-close
    size="70%"
  >
  <el-table stripe :data="list" style="width: 100%;" v-loading="loading" border :height="contentStyleObj">
    <template #empty>
      <el-empty :image-size="150" description="没有数据"></el-empty>
    </template>
    <el-table-column prop="name" label="申报列表名称" min-width="250">
      <template #default="scope">
        <span>{{$tableNameFilter(scope.row.tableName)}}</span>
      </template>
    </el-table-column>
    <el-table-column prop="name" label="取数状态" align="left" min-width="80">
      <template #default="scope">
        <div class="item_icon" v-if="scope.row.status == 1">
          <i class="iconfont icon-duihao"></i>
          <p>已取数</p>
        </div>
        <div class="item_icon" v-else>
          <i class="iconfont icon-gantanhao"></i>
          <p>未取数</p>
        </div>
      </template>
    </el-table-column>
      <el-table-column prop="name" label="取数时间" min-width="145" align="center">
      <template #default="scope">
        <span>{{$parseTime(scope.row.updatedAt,'{y}-{m}-{d} {h}:{i}:{s}')}}</span>
      </template>
    </el-table-column>
    <el-table-column prop="taskStatus" label="申报状态" align="left" min-width="190" v-if="type">
      <template #default="scope">
        <div class="operate_btn" v-if="scope.row.tableName == 'gs_gs' || scope.row.tableName == 'gs_gs_one' || scope.row.tableName == 'gs_gs_hd'">
          <div class="item_icon">
            <el-tooltip placement="top"  :disabled="!scope.row.errLog && !scope.row.businessLog" effect="dark">
              <template #content>
                <div v-html="(scope.row.errLog? scope.row.errLog:'') + (scope.row.businessLog?scope.row.businessLog:'')"></div>
              </template>
              <p>
                <span v-if="scope.row.image && (scope.row.taskStatus == 3 || scope.row.taskStatus == 4)" class="demo-image__preview" >
                  <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-if="scope.row.businessStatus == 3 ||scope.row.businessStatus == 4 ||scope.row.businessStatus == 5 || scope.row.businessStatus == 6|| scope.row.businessStatus == 12" @click="open(scope.row, scope.$index)"></i>
                  <i class="iconfont icon-picture" style="color:#e6a23c;font-size:16px;" v-if=" scope.row.businessStatus == 8 " @click="open(scope.row, scope.$index)"></i>
                  <i class="iconfont icon-picture" style="color:#67c23a;font-size:16px;" v-if="scope.row.businessStatus == 10 ||scope.row.businessStatus == 14 ||scope.row.businessStatus == 13 || scope.row.businessStatus == 20 " @click="open(scope.row, scope.$index)"></i>
                  <i class="iconfont icon-picture" style="color:#39b0d2;font-size:16px;" v-if="scope.row.businessStatus == 11 || scope.row.businessStatus == 2 || scope.row.businessStatus == 7" @click="open(scope.row, scope.$index)"></i>
                </span>
                <i v-else :class="$setUpStatusGsIconSb(scope.row.taskStatus,scope.row.businessStatus)"></i>
                <p :class="$setUpStatusGsWordSb(scope.row.taskStatus, scope.row.businessStatus)" style="border: 0;">{{$setUpStatusGsSb(scope.row.taskStatus,scope.row.businessStatus)}}</p>
              <i class="iconfont icon-wenhao" v-if="((scope.row.businessStatus == 3 ||scope.row.businessStatus == 4 ||scope.row.businessStatus == 5 || scope.row.businessStatus == 6|| scope.row.businessStatus == 12) && scope.row.businessLog)|| scope.row.taskStatus == '4'" style="color:#f56c6c;margin:0 0 0 4px;"></i>
              </p>
            </el-tooltip> 
          </div>
          <p>
            <el-button type="text" style="cursor:pointer" v-if="from" :disabled="scope.row.status != 1 || (scope.row.taskStatus == 3 && ['7','10','11','13','20'].includes(scope.row.businessStatus)) || ['1','2','99'].includes(scope.row.taskStatus)" @click="sendOne(scope.row,'tax_sb')">申报</el-button>
          </p>
        </div>
        <el-tooltip placement="top"  v-else :disabled="!scope.row.errLog && !scope.row.businessLog" effect="dark">
          <template #content>
            <div v-html="scope.row.errLog? scope.row.errLog:'' + scope.row.businessLog?scope.row.businessLog:''"></div>
          </template>
          <div class="operate_btn">
            <p class="item_icon">
              <span v-if="scope.row.image" class="demo-image__preview" >
                <i class="iconfont icon-info" v-if="scope.row.taskStatus == 1"></i>
                <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-else-if="scope.row.businessStatus == 3" @click="open(scope.row, scope.$index)"></i>
                <i class="iconfont icon-picture" style="color:#67c23a;font-size:16px;" v-else-if="scope.row.businessStatus == 2 ||scope.row.businessStatus == 6 ||scope.row.businessStatus == 20  || scope.row.businessStatus == 22" @click="open(scope.row, scope.$index)"></i>
                <i class="iconfont icon-picture" style="color:#39b0d2;font-size:16px;" v-else-if="scope.row.businessStatus == 4 || scope.row.businessStatus == 5 || scope.row.businessStatus == 40 || scope.row.businessStatus == 60 || scope.row.businessStatus == 21" @click="open(scope.row, scope.$index)"></i>
                <i class="iconfont icon-picture" style="color:#e6a23c;font-size:13px;" v-else-if="scope.row.businessStatus == 23" @click="open(scope.row,scope.$index)"></i>

              </span>
              <i v-if="!scope.row.image" :class="$batchSendIconSb(scope.row.taskStatus, scope.row.businessStatus)"></i>
              <p size="small" :class="$batchSendWordSb(scope.row.taskStatus, scope.row.businessStatus)" style="border: 0;">{{$batchSendSb(scope.row.taskStatus,scope.row.businessStatus)}}</p>
              <i class="iconfont icon-wenhao" v-if="scope.row.businessStatus == 3 && scope.row.businessLog" style="color:#f56c6c;margin:0 0 0 4px;"></i>
            </p>
            <p>
              <el-button type="text" style="cursor:pointer" v-if="from" :disabled="scope.row.status != 1 || (scope.row.taskStatus == 3 && !['3','23'].includes(scope.row.businessStatus)) || ['1','2','99'].includes(scope.row.taskStatus)" @click="sendOne(scope.row,'tax_sb')">申报</el-button>
            </p>
          </div>
        </el-tooltip>

      </template>
    </el-table-column>
    <el-table-column prop="name" label="申报详情" min-width="85" align="center" v-if="type">
      <template #default="scope">
        <el-button type="text" style="cursor:pointer" @click="declareDetail(scope.row)" :disabled="scope.row.taskStatus == 0 || scope.row.taskStatus == ''">查看记录</el-button>
      </template>
    </el-table-column>
    <el-table-column prop="name" label="扣款状态" align="left" min-width="160"  v-if="type">
      <template #default="scope">
        <el-tooltip placement="top" :disabled="!scope.row.errLog2 && !scope.row.businessLog2" effect="dark">
          <template #content>
            <div v-html="scope.row.errLog2?scope.row.errLog2:'' + scope.row.businessLog2?scope.row.businessLog2:''"></div>
          </template>
          <div class="operate_btn">
            <p class="item_icon">
              <span v-if="scope.row.image2">
                <i class="iconfont icon-info" v-if="scope.row.taskStatus2 == 1"></i>
                <i class="iconfont icon-picture" style="color:red;font-size:16px;" v-else-if="scope.row.businessStatus2 == 3 || scope.row.businessStatus2 == 5" @click="open2(scope.row, scope.$index)"></i>
                <i class="iconfont icon-picture" style="color:#67c23a;font-size:16px;" v-else-if="scope.row.businessStatus2 == 2 ||scope.row.businessStatus2 == 13 ||scope.row.businessStatus2 == 22" @click="open2(scope.row, scope.$index)"></i>
              </span>
              <i :class="$batchSendIconSb(scope.row.taskStatus2, scope.row.businessStatus2)" v-else></i>
              <p>{{$batchSendKk(scope.row.taskStatus2,scope.row.businessStatus2)}}</p>
            </p>
            <p>
              <el-button type="text" style="cursor:pointer" v-if="from" :disabled="scope.row.taskStatus2 != 4 && (scope.row.taskStatus2 != 3 || scope.row.businessStatus2 != 3)" @click="sendOne(scope.row,'tax_kk')">扣款</el-button>
            </p>
          </div>
        </el-tooltip>
      </template>
    </el-table-column>
    <el-table-column prop="name" label="扣款详情" min-width="85" align="center" v-if="type">
      <template #default="scope">
        <el-button type="text" style="cursor:pointer" @click="deductionDetail(scope.row)" :disabled="scope.row.taskStatus2 == 0 || scope.row.taskStatus2 == ''">查看记录</el-button>
      </template>
    </el-table-column>
    <el-table-column prop="name" label="税款" align="center" min-width="120">
      <template #default="scope">
        <span v-if="scope.row.tax && scope.row.tax != '0'">{{scope.row.tax}}
          <span v-if="scope.row.tax_fjs && scope.row.tax_fjs != '0'"> + {{scope.row.tax_fjs}}</span>
          <span v-if="scope.row.taxOnly && scope.row.taxOnly != '0'"> + {{scope.row.taxOnly}}</span>
        </span>
        <span v-else>
          {{0}}
          <span v-if="scope.row.tax_fjs && scope.row.tax_fjs != '0'"> + {{scope.row.tax_fjs}}</span>
          <span v-if="scope.row.taxOnly && scope.row.taxOnly != '0'"> + {{scope.row.taxOnly}}</span>
        </span>
      </template>
    </el-table-column>
    <el-table-column prop="name" label="税负率" align="center" min-width="80">
      <template #default="scope">
        <span  v-if="scope.row.sf">{{scope.row.sf}}</span>
        <span v-else>{{0}}</span>
      </template>
    </el-table-column>
    <el-table-column prop="name" label="年税负率" align="center" min-width="80">
      <template #default="scope">
        <span v-if="scope.row.sfYear">{{scope.row.sfYear}}</span>
        <span v-else>{{0}}</span>
      </template>
    </el-table-column>
    </el-table>
    </el-drawer>
  <declareDetails ref="declareDetails"></declareDetails>
  <sbImage ref="sbImage"></sbImage>
  <settingDialog ref="settingDialog"></settingDialog>
</template>

<script>
import { taxList} from "@/api/taxCalculation"
import declareDetails from "../../batch/components/declareDetails.vue"
import sbImage from '@/components/sbImage'
import { sendTask } from "@/api/collection"
export default {
  name:'autoTaxDetail',
  components: { 
    declareDetails,
    sbImage,
   },
   props:{
    from:{
      type:String,
      default:''
    }
   },
  data() {
    return {
      list:[],
      activeName:'first',
      loading:false,
      tableNames:[],
      settingMsg:'',
      comId:null,
      period:"",
      dialogTableVisible:false,
      type:"",
      comName:""
    }
  },
  created(){
    this.contentStyleObj= this.$getHeight(165)
  },
  methods:{
    init(row,period,type){
      this.type = type;
      this.comName = row.name
      this.comId = row.comId;
      this.period = period
      this.dialogTableVisible = true
      this.getList()
    },
    getList() {
      this.loading = true
      taxList({comId:this.comId,period:this.period}).then(res=>{
        this.loading = false
        if(res.data.msg == 'success'){
          this.list = res.data.data.list ? res.data.data.list : []
        }
      })
    },
    declareDetail(e) {
      this.$refs.declareDetails.init(e.comId,this.period,e.taskName)
    },
    deductionDetail(e) {
      this.$refs.declareDetails.init(e.comId,this.period,e.taskName2)
    },
    //单独发任务
    sendOne(row,type){
      let text = type == 'tax_sb' ? '申报' : '扣款'
      this.$confirm(`确定要${text}吗？`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.loading = true
        let param = {
          taskName: type,
          gsMainIds: [row.id]
        }
        sendTask(param).then(res => {
          this.loading = false
          if(res.data.msg == 'success') {
            this.$qzfMessage('任务已发起',3)
            this.getList()
            this.$queueMessage(res.data.data.queue)
          }
        })
      })
    },
     // 个税申报图展示
     open(row, index) {
      let imageAll = []
      let arr = []
      let currentIndex = index
      this.list.map((res,i)=>{
        if(res.image.match(',')){
          let imgList = []
          let imgUrls = res.image.split(',')
          imgList = imgUrls.filter(v=>{return v})
          imgList.map((item,i2)=>{
            if(item){
              arr.push({
                img:'https://file.listensoft.net' + item + "?" + Math.random(10),
                tableName:res.tableName
              })
              if(i< index && i2>0){
                currentIndex ++
              }
            }
          })
        }else{
          if(res.image){
            arr.push({
              img:'https://file.listensoft.net' + res.image + "?" + Math.random(10),
              tableName:res.tableName
            })
          }else{
            if(i<index){
              currentIndex --
            }
          }
        }
      })
      let url = {
        name:this.comName,
        url:arr,
      }
      imageAll.push(url)
      this.$refs.sbImage.dialog(imageAll, 0,currentIndex)
    },
    open2(row,index){
      let imageAll = []
      let arr = []
      let currentIndex = index
      this.list.map((res,i)=>{
        if(res.image2.match(',')){
          let imgList = []
          let imgUrls = res.image2.split(',')
          imgList = imgUrls.filter(v=>{return v})
          imgList.map((item,i2)=>{
            if(item){
              arr.push({
                img:'https://file.listensoft.net' + item + "?" + Math.random(10),
                tableName:res.tableName
              })
              if(i< index && i2>0){
                currentIndex ++
              }
            }
          })
        }else{
          if(res.image2){
            arr.push({
              img:'https://file.listensoft.net' + res.image2 + "?" + Math.random(10),
              tableName:res.tableName
            })
          }else{
            if(i<index){
              currentIndex --
            }
          }
        }
      })
      let url = {
        name:this.comName,
        url:arr,
      }
      imageAll.push(url)
      this.$refs.sbImage.dialog(imageAll, 0,currentIndex)
    },
  }
}
</script>

<style lang="scss" scoped>
.operate_btn{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.item_icon{
  display: inline-block;
  cursor: pointer;
  i{
    display: inline-block;
    font-size: 13px;
    margin-right:4px;
  }
  p{
    display: inline-block;
    font-size: 13px;
  }
}
</style>