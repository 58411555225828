<template>
  <el-drawer
    class="elDrawerDetail"
    :title="paramCheck.comName"
    v-model="visible"
    :direction="direction"
    destroy-on-close
    size="70%"
  >
    <!-- 社保检查 -->
    <checkCjSheBao :paramCheck="paramCheck"></checkCjSheBao>
  </el-drawer>
</template>

<script>
import checkCjSheBao from "../../batch/components/checkCjSheBao.vue";
export default {
  name: "autoShebaoDetail",
  components: {
    checkCjSheBao,
  },
  data() {
    return {
      visible: false,
      paramCheck: {},
    };
  },
  methods: {
    openDialog(row) {
      this.paramCheck = row;
      this.visible = true;
    },
  },
};
</script>
